// // import { Modal } from "bootstrap";
//
// try {
//
//
//
//   window.onload = function() {
//     let iframe = document.querySelector('.calendly-inline-widget iframe');
//
//     // console.log('12313', myiFrame)
//     // myiFrame.onload = function () {
//     //   console.log(iframe.contentDocument.body);
//     // }
//
//     console.log(iframe.contentWindow);
//     console.log(iframe.contentDocument );
//
//
//     // doc.body.innerHTML = doc.body.innerHTML + '<style>.lmtWIHO_gkbTeeyuvoJC{border: 1px solid red}</style>';
//   }
//
//   setTimeout(() => {
//     const dada = document.querySelector('._cUP1np9gMvFQrcPftuf');
//     console.log(dada);
//   }, 2000)
// } catch (e) {
//   console.log(e);
// }