import '../../node_modules/bootstrap/dist/js/bootstrap.min';
import '../../components/navbar/navbar.jsx';
import '../../components/lead-modal/lead-modal.jsx';
import '../../js/remove-paragraph-from-headers.jsx';
import '../../js/event-goal-push.jsx';
import '../../js/type-writter.jsx';
import './home.scss';
import { tns } from "tiny-slider"

try {
  tns({
    container: '.studies-slider',
    items: 1,
    slideBy: 'page',
    nav: false,
    autoplay: false,
    prevButton: document.querySelector('.studies-section .btn-prev'),
    nextButton: document.querySelector('.studies-section .btn-next')
  });


  tns({
    container: '.customers-slider',
    items: 1,
    slideBy: 'page',
    nav: false,
    autoplay: false,
    prevButton: document.querySelector('.customers-section .btn-prev'),
    nextButton: document.querySelector('.customers-section .btn-next')
  });
} catch (e) {
  console.log(e);
}

try {
  const featuredButtons = document.querySelectorAll('.features-section .btn-featured');

  if(featuredButtons) {
    featuredButtons.forEach(elem => elem.addEventListener('click', () => {
      window.location.href = elem.querySelector('a')?.getAttribute('href');
    }))
  }
} catch (e) {
  console.log()
}
