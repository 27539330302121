try {
  const navbarLinks = document.querySelectorAll('.navbar-nav .menu-item a');

  navbarLinks.forEach(elem => {
    const name = elem && elem.innerHTML.replace(/ /g, '_').toLowerCase();
    elem.setAttribute('data-event-goal', `nav_${name}`);
    elem.setAttribute('data-event-category', `navigation_main_menu`);
    elem.setAttribute('data-event-label', `menu_${name}`);
  })
} catch (e) {
  console.log(e);
}
