try {
  if(dataLayer) {
    const eventGoals = document.querySelectorAll('[data-event-goal]');
    eventGoals.forEach(elem => elem.addEventListener('click', () => {
      const eventName = elem.getAttribute('data-event-goal');
      const eventCategory  = elem.getAttribute('data-event-category');
      const eventLabel  = elem.getAttribute('data-event-label');

      dataLayer.push({
        'event': 'GA',
        'event_type': eventName,
        'event_category': eventCategory,
        'event_action': 'click',
        'event_label': eventLabel
      });
    }))
  }
} catch (e) {
  console.log(e)
}
