import Typewriter from 'typewriter-effect/dist/core';

try {
  const typedElements = document.querySelectorAll('[data-typed]');

  if(typedElements) {
    typedElements.forEach(elem => {
      const word = elem.getAttribute('data-typed');

      new Typewriter(elem, {
        strings: word,
        autoStart: true,
      });
    })
  }

} catch (e) {
  console.log(e);
}